import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { CommandStaffLogo } from "../components/CommandStaffLogo"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Section } from "../components/TableOfContents"

type PolicyHeading = {
  title: string
  id: string
}

type PolicyManualNode = {
  node: {
    sectionTitle: PolicyHeading[]
    subSections: PolicyHeading[]
    sectionContent: string
  }
}

type PolicyManualData = {
  allMarkdownRemark: {
    edges: PolicyManualNode[]
  }
}

const PolicyManualPage: React.FC = () => {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery<PolicyManualData>(graphql`
      query MyQuery {
          allMarkdownRemark(
              sort: { fields: fileAbsolutePath },
              filter: { fileAbsolutePath: { regex: "/.*\\/sections\\/.*\\.md/" } }
          ) {
              edges {
                  node {
                      sectionTitle: headings(depth: h1) {
                          title: value
                          id
                      }
                      subSections: headings(depth: h2) {
                          title: value
                          id
                      }
                      sectionContent: html
                  }
              }
          }
      }
  `)

  const tableOfContents = edges.map<Section>(
    ({
      node: {
        sectionTitle: [sectionTitle],
        subSections,
      },
    }) => ({
      ...sectionTitle,
      subSections,
    })
  )

  return (
    <Layout tableOfContents={tableOfContents}>
      <SEO title="Unit Policy Manual" />

      <div id="policies">
        
        {edges.map(({ node: { sectionTitle: [section], sectionContent } }) => (
          <div
            key={section.id}
            dangerouslySetInnerHTML={{
              __html: sectionContent,
            }}
          />
        ))}
      </div>
    </Layout>
  )
}

export default PolicyManualPage
